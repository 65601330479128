<template>
  <div class="">
    <input
      type="text"
      :class="classe"
      v-if="type == 'text'"
      :value="value"
      :placeholder="placeholder"
      @input="onUpdate"
    />
    <textarea
      type="text"
      :class="classe"
      v-if="type == 'text-long'"
      :placeholder="placeholder"
      :value="value"
      rows="3"
      @input="onUpdate"
    ></textarea>
    <vue-numeric
      :class="classe + ' text-right'"
      decimal-separator="."
      :currency="UNITE"
      separator="space"
      :precision="type == 'entier' ? 0 : 2"
      currency-symbol-position="suffix"
      empty-value="0"
      :value="value"
      :placeholder="placeholder"
      v-if="type == 'entier' || type == 'reel'"
      @input="onUpdate"
    ></vue-numeric>
    <date-picker
      :class="classe + ' text-center'"
      v-if="type == 'date'"
      :config="datepicker"
      :value="value"
      :placeholder="placeholder"
      @input="onUpdate"
    ></date-picker>
    <input
      type="hidden"
      :name="name"
      :value="value"
      :placeholder="placeholder"
    />
  </div>
</template>

<script>
import vSelect from "vue-select";
import Vue from "vue";
import moment from "moment";

Vue.use(moment);
Vue.component("v-select", vSelect);

export default {
  name: "DynamicInput",
  data() {
    return {
      datepicker: {
        format: "DD/MM/YYYY",
        useCurrent: true,
      },
    };
  },
  props: {
    type: {
      type: String,
      default: "text",
      required: false,
    },
    classe: {
      type: String,
      default: "",
      required: false,
    },
    name: {
      type: String,
      default: "",
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
      required: false,
    },
    value: {
      default: "",
      required: false,
    },
  },
  created() {
    // if(this.type == "date" && (this.model == {} || this.model == "" || this.model == null)){
    // this.value = moment().format('DD/MM/YYYY');
    // }
  },
  methods: {
    onUpdate(e) {
      // this.value = val;
      console.log(e);
      if (this.type == "date" || this.type == "entier" || this.type == "reel") {
        this.$emit("input", e);
      } else {
        this.$emit("input", e.target.value);
      }
    },
  },
};
</script>